import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Row, Col, InputGroup, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { getClientBankingAction, getTransactionsAction } from '../../../redux/actions/payments/paymentTransactions';
import { SearchList, TransactionTypeEnum } from '../../../models/Payment';
import Select from 'react-select';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import FormHeader from '../../clients/components/forms/FormHeader';
import { connect, useDispatch } from "react-redux";
import moment from 'moment';
import CurrencyInput from "../../../components/currency/CurrencyInput";
import { ReportType } from "../../../models/Reports";
import { Client, ClientName, DepartmentName } from "../../../models/Client";
import { DELETE_CLIENT_BANKING_SUCCESS, getClientsAction, SAVE_CLIENT_BANKING_SUCCESS } from "../../../redux/actions/clients/clients";
import { User, UserTypeEnum } from "../../../models/User";
import { sendErrorToastAction } from "../../../redux/actions/toast";
import _ from 'lodash';


interface IAdvancedSearch {
    formSchema: any;
    transactionType: any;
    reportType: ReportType;
    isFetching: boolean;
    searchReady: boolean;
    currentUser: User;
    clients: Array<Client>;
    clientNames: Array<ClientName>;
    departmentNames: Array<DepartmentName>;
    actionResult: IActionResult;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: string;
    totalRecords?: number;
}

const AdvancedSearch = ({ formSchema, transactionType, reportType, searchReady, isFetching, currentUser, clients, actionResult, page, pageSize, sortBy, sortDirection }: IAdvancedSearch) => {
    const [showModal, setShowModal] = useState(false);
    const actionToken = "AdvancedSearch";
    const dispatch = useDispatch();

    const defaultStartDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD');

    const [searchList, setSearchList] = useState<SearchList>(Object.assign(new SearchList(), { startDate: defaultStartDate, endDate: defaultEndDate }));
    const [trigger, setTrigger] = useState(false);
    const [clientList, setClientList] = useState<any>([]);
    const [executeDynamicQuery, setExecuteDynamicQuery] = useState<boolean>(false);


    const search = () => {
        if (reportType != ReportType.ClientBanking) {
            let _searchList = new SearchList();

            Object.entries(searchList).map(([key, value]) => {
                if (!["startDate", "endDate", "startTime", "endTime", "startAmount", "endAmount", "customSelectComponent", "departmentIds"].some(k => key === k)) {
                    (_searchList[key as keyof SearchList] as keyof typeof key) = value as keyof typeof key;
                }
            })

            if (searchList.startAmount || searchList.endAmount) {
                _searchList.totalAmount = ((searchList.startAmount) ? `${searchList.startAmount}` : "-9999999.99") + ((searchList.endAmount) ? `<->${searchList.endAmount}` : "<->9999999.99");
            }

            if (searchList.customSelectComponent && searchList.customSelectComponent?.length > 0) {
                _searchList.transactionType = searchList.customSelectComponent.map(({ value }) => `${TransactionTypeEnum[value]}`).join("|");
                if (_searchList.transactionType.includes(TransactionTypeEnum["Void"].toString())) _searchList.transactionType += "|" + TransactionTypeEnum["Reversal"];
                if (_searchList.transactionType.includes(TransactionTypeEnum["Refund"].toString())) _searchList.transactionType += "|" + TransactionTypeEnum["Return"];
            }

            if (searchList.paymentTypeSelect && searchList.paymentTypeSelect?.length > 0) {
                _searchList.paymentType = searchList.paymentTypeSelect.map(({ value }) => `${value}`).join("|");
            }

            if (searchList.departmentIds && searchList.departmentIds?.length > 0) {
                _searchList.departmentId = searchList.departmentIds.map((id) => `${id}`).join("|");
            }

            _searchList.createdAt = `${moment(searchList.startDate + ((searchList.startTime) ? "T" + searchList.startTime + ":00.000" : "T00:00:00.000")).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}<->${moment(searchList.endDate + ((searchList.endTime) ? "T" + searchList.endTime + ":59.999" : "T23:59:59.999")).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}`;

            if (executeDynamicQuery) {
                delete _searchList.paymentTypeSelect;
                dispatch(getTransactionsAction(1, 10, _searchList, actionToken, currentUser, transactionType, reportType));
            }
        }
        if (reportType === ReportType.ClientBanking) {
            let _searchList = new SearchList();
            Object.entries(searchList).map(([key, value]) => {
                if (!["startDate", "endDate", "departmentIds"].some(k => key === k)) {
                    (_searchList[key as keyof SearchList] as keyof typeof key) = value as keyof typeof key;
                }
            })

            if (searchList.departmentIds && searchList.departmentIds?.length > 0) {
                _searchList.departmentId = searchList.departmentIds.map((id) => `${id}`).join(",");
            }

            const clientBankingSearchValues = _.pickBy(_searchList, value => value)
            dispatch(getClientBankingAction(page!, pageSize!, clientBankingSearchValues, actionToken, sortBy, sortDirection));
        }
    }

    useEffect(() => {
        if (!clients) {
            dispatch(getClientsAction(1, 1000));
        }
        const clientDropdown = clients?.filter(clientDetails => currentUser?.userClients.some(userClient => clientDetails.msbId === userClient.clientMSBId));
        setClientList(clientDropdown);
    }, [clients]);

    useEffect(() => {
        search();
    }, [trigger,page,pageSize,sortBy,sortDirection,executeDynamicQuery]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.result === SAVE_CLIENT_BANKING_SUCCESS || actionResult.result === DELETE_CLIENT_BANKING_SUCCESS) {
                setTrigger(!trigger);
            }
        }
    }, [actionResult]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (moment(searchList.startDate + ((searchList.startTime) ? "T" + searchList.startTime + ":00.000" : "T00:00:00.000")).utc() > moment(searchList.endDate + ((searchList.endTime) ? "T" + searchList.endTime + ":59.999" : "T23:59:59.999")).utc()) {
            dispatch(sendErrorToastAction(`Start date/time must be less than end date/time.`));
            return;
        }
        if (searchList.startAmount && searchList.endAmount && (Number(searchList.startAmount) > Number(searchList.endAmount))) {
            dispatch(sendErrorToastAction(`Start amount must be less than end amount.`));
            return;
        }
        if (showModal) setTimeout(() => setShowModal(false), 100);
        setTrigger(!trigger);
        setExecuteDynamicQuery(true);
    };

    const clearForms = (event: any) => {
        event.preventDefault();
        setSearchList(Object.assign(new SearchList(), { startDate: defaultStartDate, endDate: defaultEndDate, clientIds: '' }));
        setTrigger(!trigger);
    }

    const onHideModal = () => {
        setTimeout(() => setShowModal(false), 100);
    }

    const getFormElement = (elementName: any, elementSchema: any) => {
        const props = {
            name: elementName,
            label: elementSchema.label,
            options: elementSchema.options
        };

        if (elementSchema.type === "select") {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Form.Control as="select" value={searchList[elementName as keyof SearchList]} onChange={(e: any) => setSearchList({ ...searchList, [elementName as keyof SearchList]: e.target.value })}>
                        <option key={0} value="" >Select...</option>
                        {props.options.map((optn: any, index: any) => <option key={index} value={optn.value} label={optn.label || optn.value} />)}
                    </Form.Control>
                </Form.Group>
            )
        }
        else if (elementSchema.type === "currency") {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <CurrencyInput
                        className="form-control"
                        placeholder="$ 0.00"
                        maxLength={10}
                        decimalsLimit={2}
                        prefix="$ "
                        value={searchList[elementName as keyof SearchList]}
                        onValueChange={(value, name) => setSearchList({ ...searchList, [elementName as keyof SearchList]: value })}
                    />
                </Form.Group>
            )
        }
        else if (elementName === 'customSelectComponent') {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={searchList.customSelectComponent}
                        onChange={(selected: any) => setSearchList({ ...searchList, customSelectComponent: selected })}
                        options={props.options}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </Form.Group>
            )
        }
        else if (elementName === 'paymentTypeSelect') {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={searchList.paymentTypeSelect}
                        onChange={(selected: any) => setSearchList({ ...searchList, paymentTypeSelect: selected })}
                        options={props.options}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </Form.Group>
            )
        }
        else {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Form.Control type={elementSchema.type} value={searchList[elementName as keyof SearchList]} onChange={(e: any) => setSearchList({ ...searchList, [elementName as keyof SearchList]: e.target.value })} />
                </Form.Group>
            )
        }
    }

    return (
        <>
            <Form className="table-search advanced-search" onSubmit={handleSubmit}>
                <Row>

                    {reportType === ReportType.ClientBanking ?
                        <>
                            <Col>
                                <Form.Group controlId="client">
                                    <Form.Label>Client</Form.Label>
                                    <Form.Control as="select" type="input" required value={searchList.clientIds} onChange={(e: any) => setSearchList({ ...searchList, clientIds: e.target.value })}>
                                        <option key={"client_default"} value="" selected disabled hidden>Select Client</option>
                                        {clientList?.length > 1 &&
                                            clientList?.map((client: any, index: any) => (
                                                <option key={`client_${client.msbId}`} value={client.msbId}>{client.businessName}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </> :
                        <>
                            <Col xl={4} lg={6} md={6} sm={12}>
                                <Form.Group controlId="startDate">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control type="date" name="startDate" max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.startDate} onChange={(e: any) => setSearchList({ ...searchList, startDate: e.target.value })} disabled={isFetching} />
                                </Form.Group>
                            </Col>
                            <Col xl={4} lg={6} md={6} sm={12}>
                                <Form.Group controlId="endDate">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control type="date" name="endDate" min={moment(searchList.startDate).format('YYYY-MM-DD')} max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.endDate} onChange={(e) => setSearchList({ ...searchList, endDate: e.target.value })} disabled={isFetching} />
                                </Form.Group>
                            </Col>
                        </>
                    }
                    <Col xl={4} lg={12} md={12} sm={12}>
                        <ButtonToolbar>
                            <Button variant="link" onClick={() => setShowModal(true)} disabled={isFetching}>Advanced Search</Button>
                            <Button variant="outline-secondary" onClick={clearForms} disabled={isFetching}>Reset</Button>
                            <Button type="submit" disabled={isFetching}>Search</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>

            <Modal show={showModal} onHide={onHideModal} size="lg" className="advanced-search-modal">
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />
                    <Form className="advanced-search-form" onSubmit={handleSubmit}>
                        {reportType != ReportType.ClientBanking &&
                            <Row>
                                <Col>
                                    <Form.Group controlId="startDate">
                                        <Form.Label>Start date</Form.Label>
                                        <Form.Control type="date" name="startDate" required={moment(searchList.endDate, 'YYYY-MM-DD', true).isValid()} max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.startDate} onChange={(e) => setSearchList({ ...searchList, startDate: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="endDate">
                                        <Form.Label>End date</Form.Label>
                                        <Form.Control type="date" name="endDate" required={moment(searchList.startDate, 'YYYY-MM-DD', true).isValid()} min={moment(searchList.startDate).format('YYYY-MM-DD')} max={moment(defaultEndDate).format('YYYY-MM-DD')} value={searchList.endDate} onChange={(e) => setSearchList({ ...searchList, endDate: e.target.value })} />
                                    </Form.Group>
                                </Col>
                            </Row>}
                        <Row>
                            {Object.keys(formSchema).map((key, ind) => (
                                <div className="col-sm-6" key={key}>
                                    {getFormElement(key, formSchema[key])}
                                </div>
                            ))}
                        </Row>
                        <Row>
                            {
                                searchReady && clients &&
                                <DualListBox
                                    selected={searchList.departmentIds}
                                    canFilter
                                    options={
                                        currentUser?.userClients.map(uc => {
                                            let client = clients.find(c => c.msbId === uc.clientMSBId);
                                            if (client) {
                                                return {
                                                    label: client?.businessName,
                                                    options: (currentUser.userTypeEnum == UserTypeEnum.Client) ? currentUser?.userDepartments.map(ud => {
                                                        let department = client?.departments.find(d => d.msbId === ud.departmentMSBId);
                                                        if (department) {
                                                            return {
                                                                label: department?.name, value: department?.msbId
                                                            }
                                                        }
                                                    }).filter(Boolean).sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
                                                        : client?.departments.map(d => { return { label: d.name, value: d.msbId } }).sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
                                                }
                                            }
                                        }).filter(Boolean).sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
                                    }
                                    onChange={(ids: any) => setSearchList({ ...searchList, departmentIds: ids })}
                                    className="transfer"
                                    showHeaderLabels={true}
                                    lang={{
                                        availableHeader: reportType === ReportType.ClientBanking ? 'All Clients and Departments' : 'All departments',
                                        selectedHeader: reportType === ReportType.ClientBanking ? 'Selected Clients and Departments' : 'Selected departments'
                                    }}
                                    icons={{
                                        moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                        moveAllLeft: [
                                            <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                            <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                        ],
                                        moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                        moveAllRight: [
                                            <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                            <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                        ],
                                    }}
                                />
                            }
                        </Row>
                        <div className="advanced-search-footer">
                            <Button type="submit">
                                Search
                            </Button>
                            <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        currentUser: state.auth.currentUser,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        actionResult: state.clients.actionResult,

    };
};

export default connect(mapStateToProps)(AdvancedSearch);