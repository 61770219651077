import { connect, useDispatch } from "react-redux";
import { Button, Form, Image, Card, Accordion, ListGroup, Row, Col, ButtonToolbar, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import icoExpanded from "../../../assets/img/icons/ico-PlusSquare.svg";
import icoCollapsed from "../../../assets/img/icons/ico-MinusSquare.svg";
import { User } from '../../../models/User';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
import '../settings.css';
import { NotificationType } from '../../../models/AccountSettings';
import _ from 'lodash';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BasicTableWithProps from '../../../components/TableProviderKit';
import PaymentChannelTable from './PaymentChannelNotifications';
import { clearNotifications, getNotificationTypesAction, getPaymentChannelNotificationsAction, SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS, savePaymentChannelNotificationSequentially } from '../../../redux/actions/account/notificationSettings';
import { useEffect } from 'react';
import { sendSuccessToastAction } from "../../../redux/actions/toast";

export interface IAccountNotificationSettings {
    isFetching: boolean,
    isFetchingNotifications: boolean,
    currentUser: User,
    client: any,
    actionResult: IActionResult,
    notificationTypes: Array<NotificationType>,
    onClose: any,
    paymentChannelNotifications: any,
    emailPaymentChannelNotifications: any,
    deletePaymentChannelNotifications: any
}

const NotificationSettings = ({ isFetching, isFetchingNotifications, currentUser, client, notificationTypes, onClose, paymentChannelNotifications, emailPaymentChannelNotifications, deletePaymentChannelNotifications }: IAccountNotificationSettings) => {
    const departments = _.filter(client?.departments, (allDepartments: any) => _.some(currentUser?.userDepartments, userDepartments => userDepartments.departmentMSBId == allDepartments.msbId))
    const dispatch = useDispatch();

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'name',
            text: 'Department name',
            editable: false,
        }
    ];

    useEffect(() => {
        dispatch(getNotificationTypesAction('Notifications'));
        dispatch(getPaymentChannelNotificationsAction('Notifications'));
    }, []);

    const handleSubmit = () => {
        const handleNotificationObject = (paymentChannelNotifications: any, notificationTypes: any) => {
            return paymentChannelNotifications?.flatMap((paymentChannelNotification: any) => {

                const transactionTypesToInclude = [
                    ...(paymentChannelNotification.voids ? ['Void', 'Reversal'] : []),
                    ...(paymentChannelNotification.refunds ? ['Refund', 'Return'] : []),
                    ...(paymentChannelNotification.chargebacks ? ['Chargeback'] : [])
                ];

                const existingPairs = notificationTypes.flatMap((existingPaymentChannelNotification: any) =>
                    existingPaymentChannelNotification.emailNotificationPaymentChannels.map((paymentChannel: any) => ({
                        emailNotificationId: existingPaymentChannelNotification.notificationId,
                        paymentChannelId: paymentChannel.paymentChannelId,
                        notificationMsbId: existingPaymentChannelNotification.msbId
                    }))
                );

                const modifiedObject = notificationTypes
                    .filter((notificationType: any) => transactionTypesToInclude.includes(notificationType.transactionType))
                    .map((filteredItem: any) => ({
                        isEnabled: true,
                        paymentChannelId: paymentChannelNotification.msbId,
                        emailNotificationId: filteredItem.notificationId,
                        emailRecipients: [`${currentUser?.email}`],
                        notificationMsbId: filteredItem.msbId
                    }))

                const existingPaymentChannels: any = [];
                const newPaymentChannels: any = [];

                modifiedObject.forEach((item: any) => {
                    existingPairs.some((pair: any) =>
                        item.emailNotificationId === pair.emailNotificationId &&
                        item.paymentChannelId === pair.paymentChannelId
                    ) ? existingPaymentChannels.push(item) : newPaymentChannels.push(item)
                });

                return {
                    existingPairs: existingPaymentChannels,
                    newObjects: newPaymentChannels
                };
            });
        };

        const result = handleNotificationObject(paymentChannelNotifications, notificationTypes);

        const existingPairs = _.flatten(result?.map((r: any) => r.existingPairs));
        const newObjects = _.flatten(result?.map((r: any) => r.newObjects));

        const filteredExistingPairs = _.filter(existingPairs, (existingPair: any) => {
            return !_.find(emailPaymentChannelNotifications, (channelNotifications: any) =>
                channelNotifications.emailNotification.msbId === existingPair.notificationMsbId &&
                channelNotifications.paymentChannelId === existingPair.paymentChannelId &&
                _.some(channelNotifications.emailRecipients, recipient =>
                    _.includes(existingPair.emailRecipients, recipient.emailAddress)
                )
            );
        });

        const bulkAddRelationships = _.mapValues(_.groupBy(filteredExistingPairs, 'notificationMsbId'), (group: any) => ([
            {
                emailAddress: group[0].emailRecipients[0],
                paymentChannelIds: _.uniq(group.map((item: any) => item.paymentChannelId))
            }]));


        const markedForDeletion = (deleteNotifications: any) => (_.isEmpty(deleteNotifications) || Object.values(deleteNotifications).every(_.isEmpty)) ? {} : deleteNotifications;

        dispatch((savePaymentChannelNotificationSequentially(newObjects, bulkAddRelationships, markedForDeletion(deletePaymentChannelNotifications), 'notifications')))
        close();
    }

    const SearchFilter = (props: any) => {
        let input: any;
        const handleSubmit = (event: any) => {
            event.preventDefault();
            props.onSearch(input.value);
        };
        const handleReset = () => {
            props.onSearch(input.value = '');
        }
        return (
            <Form onSubmit={handleSubmit} className="table-search">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Department</Form.Label>
                            <Form.Control ref={(filterValue: any) => input = filterValue} type="text" placeholder="Enter department" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <ButtonToolbar>
                            <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                            <Button onClick={handleSubmit}>Search</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>
        );
    };

    const close = () => {
        onClose();
        dispatch((clearNotifications('notifications')))
    }

    const expandRow = {
        showExpandColumn: true,
        expandByColumnOnly: true,
        onlyOneExpanding: true,
        expandColumnWidth: 10,
        expandColumnRenderer: ({ expanded }: any) => {
            return (
                <Image src={expanded ? icoCollapsed : icoExpanded} alt={expanded ? "Collapse icon" : "Expand icon"} />
            );
        },
        renderer: (row: any) => {
            const matchingNotifications = _.filter(emailPaymentChannelNotifications, (item) =>
                _.some(item.emailRecipients, { emailAddress: currentUser?.email })
            );

            const updatedArray = row.paymentChannels.map((paymentChannel: any) => {
                const updatedItem = { ...paymentChannel };

                matchingNotifications.forEach((notification: any) => {
                    if (notification.emailNotification.transactionType === 'Chargeback' &&
                        notification.paymentChannelId === paymentChannel.msbId) {
                        updatedItem.chargebacks = true;
                    }
                    if ((notification.emailNotification.transactionType === 'Void' ||
                        notification.emailNotification.transactionType === 'Reversal') &&
                        notification.paymentChannelId === paymentChannel.msbId) {
                        updatedItem.voids = true;
                    }
                    if ((notification.emailNotification.transactionType === 'Refund' ||
                        notification.emailNotification.transactionType === 'Return') &&
                        notification.paymentChannelId === paymentChannel.msbId) {
                        updatedItem.refunds = true;
                    }
                });
                return updatedItem;
            });

            if (isFetching || isFetchingNotifications) {
                return (<Spinner animation="border" />);
            }
            else {
                return (
                    <div>
                        <PaymentChannelTable data={updatedArray} departmentName={row.name} />
                    </div>)
            }

        },
    }

    return (
        <div style={{ marginTop: '16px' }}>
            <Accordion className="agreement-form" defaultActiveKey='0'>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Payment Notifications
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <div>
                                        <ToolkitProvider keyField="msbId" data={departments} columns={columns} search>
                                            {
                                                (props: any) => (
                                                    <>
                                                        <Row>
                                                            <SearchFilter {...props.searchProps} />
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <BasicTableWithProps
                                                                    classes="notifications-custom-table"
                                                                    expandRow={expandRow}
                                                                    {...props.baseProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <div className="chargebackStatusButtons">
                <Button type="submit" onClick={handleSubmit}>
                    Save Notifications
                </Button>
                <Button className="chargebackStatusCancel" variant="link" onClick={close}>
                    Cancel
                </Button>
            </div>
        </div>
    )
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.accountSettings.isFetching,
        isFetchingNotifications: state.accountSettings.isFetchingNotifications,
        isSaving: state.accountSettings.isSaving,
        client: state.clients.client,
        currentUser: state.auth.currentUser,
        actionResult: state.accountSettings.actionResult,
        notificationTypes: state.accountSettings.notificationTypes,
        paymentChannelNotifications: state.accountSettings.paymentChannelNotifications,
        userNotificationSettings: state.accountSettings.userNotificationSettings,
        emailPaymentChannelNotifications: state.accountSettings.emailPaymentChannelNotifications,
        deletePaymentChannelNotifications: state.accountSettings.deletePaymentChannelNotifications
    };
};

export default connect(mapStateToProps)(NotificationSettings);